



















import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/auth-service';

const auth = new AuthService();

@Component
export default class Login extends Vue {
  public currentUser? = '';

  public isLoggedIn = false;

  get username(): string|undefined {
    return this.currentUser;
  }

  public login() {
    const redirect = this.$route.query.redirect as string;
    auth.login(redirect);
  }

  public logout() {
    this.$router.push('/logout');
  }

    public async mounted() {
    const user = await auth.getUser()

    if(user){
      this.currentUser = user.profile.name
      this.isLoggedIn = !auth.IsExpired(user)
    } 
  }
}
